import {
  useCreateSourceContactMutation,
  useCreateSourceMutation,
  useCreateSourceOrganizationMutation,
} from "features/api/source-api";
import type {
  CreateOrganizationPayload,
  CreateSourceContactPayload,
  CreateSourcePayload,
} from "models/source.model";
import { useIntl } from "react-intl";
import { Notification } from "services/notification";
import { ETranslations } from "types/translates";

export const useCreateSource = () => {
  const [createSource, createSourceState] = useCreateSourceMutation();
  const [createOrganization, createOrganizationState] =
    useCreateSourceOrganizationMutation();
  const [createContact, createSourceContactState] =
    useCreateSourceContactMutation();
  const { formatMessage } = useIntl();

  const handleCreateOrganization = (payload: CreateOrganizationPayload) =>
    createOrganization(payload).then((data) => {
      if (!Object.hasOwn(data, "data")) {
        Notification.error(
          formatMessage(
            { id: ETranslations.FAILED },
            {
              action: formatMessage(
                {
                  id: ETranslations.BASE_ENTITY_CREATION,
                },
                {
                  entity: formatMessage({
                    id: ETranslations.ORGANIZATION,
                  }),
                },
              ).toLowerCase(),
            },
          ),
        );
        throw data.error;
      }
      Notification.success(
        formatMessage(
          { id: ETranslations.CREATED_ENTITY },
          {
            entity: formatMessage(
              {
                id: ETranslations.NEW_UNIT,
              },
              {
                unit: formatMessage({
                  id: ETranslations.ORGANIZATION,
                }),
              },
            ).toLowerCase(),
          },
        ),
      );
      return data.data;
    });

  const handleCreateSourceContact = (payload: CreateSourceContactPayload) =>
    createContact(payload).then((data) => {
      if (!Object.hasOwn(data, "data")) {
        Notification.error(
          formatMessage(
            { id: ETranslations.FAILED },
            {
              action: formatMessage(
                {
                  id: ETranslations.BASE_ENTITY_CREATION,
                },
                {
                  entity: formatMessage({
                    id: ETranslations.CONTACT,
                  }),
                },
              ).toLowerCase(),
            },
          ),
        );
        throw data.error;
      }
      Notification.success(
        formatMessage(
          { id: ETranslations.CREATED_ENTITY },
          {
            entity: formatMessage(
              {
                id: ETranslations.NEW_UNIT,
              },
              {
                unit: formatMessage({
                  id: ETranslations.CONTACT,
                }),
              },
            ).toLowerCase(),
          },
        ),
      );
      return data.data;
    });

  const handleCreateSource = (payload: CreateSourcePayload) =>
    createSource(payload)
      .unwrap()
      .then(() =>
        Notification.success(
          formatMessage(
            { id: ETranslations.CREATED_ENTITY },
            {
              entity: formatMessage(
                {
                  id: ETranslations.NEW_UNIT,
                },
                {
                  unit: formatMessage({
                    id: ETranslations.SOURCE,
                  }),
                },
              ).toLowerCase(),
            },
          ),
        ),
      )
      .catch((e) => {
        console.log(e);
        Notification.error(
          formatMessage(
            { id: ETranslations.FAILED },
            {
              action: formatMessage(
                {
                  id: ETranslations.BASE_ENTITY_CREATION,
                },
                {
                  entity: formatMessage({
                    id: ETranslations.SOURCE,
                  }),
                },
              ).toLowerCase(),
            },
          ),
        );
      });
  return {
    handleCreateOrganization,
    handleCreateSourceContact,
    handleCreateSource,
    isLoading:
      createOrganizationState.isLoading ||
      createSourceContactState.isLoading ||
      createSourceState.isLoading,
    errors: {
      createOrganization: createOrganizationState.error,
      createSourceContact: createSourceContactState.error,
      createSource: createSourceState.error,
    },
  };
};
