import {
  type CreateOrganizationPayload,
  type CreateSourceContactPayload,
  type CreateSourcePayload,
  Organization,
  type OrganizationId,
  OrganizationSearchDTO,
  Source,
  SourceContact,
  type SourceContactId,
  SourceContactSearchDTO,
  type SourceId,
  SourceSearchDTO,
  type SourceSearchParams,
  type SourceType,
} from "models/source.model";
import { Notification } from "services/notification";

import { IResponse } from "../../models/common";
import { ErrorResponse } from "../../types/commons";
import { ETranslations } from "../../types/translates";
import { api } from "./api";

export const sourceApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "Sources",
      "Source",
      "SourceContact",
      "Organizations",
      "Organization",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchSourcesPage: build.query<
        SourceSearchDTO,
        SourceSearchParams & { page?: number; size?: number; sort?: string }
      >({
        query: (filter) => ({
          url: "v2/sources/search",
          method: "GET",
          params: filter,
        }),
        transformResponse: (response: IResponse<SourceSearchDTO>) => {
          const result = SourceSearchDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: (_, __, args) => [
          {
            type: "Sources",
            id: `${args.sort === "organizations" ? "organizations" : "contacts"}-${args.page || 0}`,
          },
        ],
      }),
      fetchSourcesContactsPage: build.query<
        SourceContactSearchDTO,
        SourceSearchParams & { page?: number; size?: number; sort?: string }
      >({
        query: (filter) => ({
          url: "v2/sources/contact/search",
          method: "GET",
          params: filter,
        }),
        transformResponse: (response: IResponse<SourceContactSearchDTO>) => {
          const result = SourceContactSearchDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: ["Sources"],
      }),
      fetchSource: build.query<Source, number | string>({
        query: (id) => ({
          url: `v2/sources/${id}`,
        }),
        providesTags: (r, e, id) => ["Sources", { type: "Source", id }],
        transformResponse: (response: IResponse<Source>) => {
          const result = Source.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
      }),
      fetchSourceContact: build.query<SourceContact, number | string>({
        query: (id) => ({
          url: `v2/sources/contact/${id}`,
        }),
        providesTags: (r, e, id) => ["Sources", { type: "SourceContact", id }],
        transformResponse: (response: IResponse<SourceContact>) => {
          const result = SourceContact.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
      }),
      fetchSourceOrganization: build.query<Organization, number | string>({
        query: (id) => ({
          url: `v2/sources/organization/${id}`,
        }),
        providesTags: (r, e, id) => ["Sources", { type: "Organization", id }],
        transformResponse: (response: IResponse<Organization>) => {
          const result = Organization.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
      }),
      fetchOrganizationPage: build.query<
        OrganizationSearchDTO,
        { s?: string; page?: number; size?: number; sort?: string }
      >({
        query: (params) => ({
          url: "v2/sources/organization/search",
          method: "GET",
          params,
        }),
        transformResponse: (response: IResponse<OrganizationSearchDTO>) => {
          const result = OrganizationSearchDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: ["Organizations"],
      }),
      createSource: build.mutation<Source, CreateSourcePayload>({
        query: (body) => ({
          url: "v2/sources",
          method: "POST",
          body,
        }),
        invalidatesTags: (r, e, args) => [
          "Sources",
          "Organizations",
          {
            type: "Source",
            id:
              r?.source_id ||
              `${args.source_organization_id}-${args.source_contact_id}`,
          },
        ],
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            //@ts-ignore
            if (data?.status === "ERROR") {
              throw data;
            }
          } catch (e) {
            const errorMessage = (e as ErrorResponse)?.error?.data
              ?.errorMessage;
            errorMessage &&
              Notification.error({
                title: ETranslations.ERROR_GUEST_UPDATING,
                message: errorMessage,
              });
            throw e;
          }
        },
      }),
      createSourceOrganization: build.mutation<
        Organization,
        CreateOrganizationPayload
      >({
        query: (body) => ({
          url: "v2/sources/organization",
          method: "POST",
          body,
        }),
        invalidatesTags: (r, e, args) => [
          "Sources",
          "Organizations",
          { type: "Organization", id: r?.id || args.name },
        ],
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            //@ts-ignore
            if (data?.status === "ERROR") {
              throw data;
            }
          } catch (e) {
            const errorMessage = (e as ErrorResponse)?.error?.data
              ?.errorMessage;
            errorMessage &&
              Notification.error({
                title: ETranslations.ERROR_GUEST_UPDATING,
                message: errorMessage,
              });
            throw e;
          }
        },
      }),
      createSourceContact: build.mutation<
        SourceContact,
        CreateSourceContactPayload
      >({
        query: (body) => ({
          url: "v2/sources/contact",
          method: "POST",
          body,
        }),
        invalidatesTags: (r, e, args) => [
          "Sources",
          "SourceContact",
          { type: "SourceContact", id: r?.id || args.phone },
        ],
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            //@ts-ignore
            if (data?.status === "ERROR") {
              throw data;
            }
          } catch (e) {
            const errorMessage = (e as ErrorResponse)?.error?.data
              ?.errorMessage;
            errorMessage &&
              Notification.error({
                title: ETranslations.ERROR_GUEST_UPDATING,
                message: errorMessage,
              });
            throw e;
          }
        },
      }),
      updateSource: build.mutation<Source, Source & { id: SourceId }>({
        query: (data) => ({
          url: `v2/sources/${data?.id}`,
          method: "PUT",
          body: {
            source_contact: data.source_contact,
          },
        }),
        invalidatesTags: (r, e, args) => [
          "Sources",
          { type: "Source", id: args.id },
        ],
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            //@ts-ignore
            if (data?.status === "ERROR") {
              throw data;
            }
          } catch (e) {
            const errorMessage = (e as ErrorResponse)?.error?.data
              ?.errorMessage;
            errorMessage &&
              Notification.error({
                title: ETranslations.ERROR_GUEST_UPDATING,
                message: errorMessage,
              });
            throw e;
          }
        },
      }),
    }),
  });

export const {
  useFetchSourcesPageQuery,
  useLazyFetchSourcesPageQuery,
  useFetchSourcesContactsPageQuery,
  useLazyFetchSourcesContactsPageQuery,
  useFetchSourceQuery,
  useLazyFetchOrganizationPageQuery,
  useFetchOrganizationPageQuery,
  useCreateSourceMutation,
  useCreateSourceOrganizationMutation,
  useCreateSourceContactMutation,
  useUpdateSourceMutation,
  useFetchSourceContactQuery,
  useFetchSourceOrganizationQuery,
} = sourceApi;
