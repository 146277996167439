import type { RequiredBy } from "types/commons";
import { ETranslations } from "types/translates";
import { z } from "zod";

import { PageableScheme } from "./common";

export const OrganizationId = z.number().int().brand("OrganizationId");
export type OrganizationId = z.infer<typeof OrganizationId>;

export const OrganizationName = z.string().brand("OrganizationName");
export type OrganizationName = z.infer<typeof OrganizationName>;

export const SourceId = z.number().int().brand("SourceId");
export type SourceId = z.infer<typeof SourceId>;

export const SourceContactId = z.number().int().brand("SourceContactId");
export type SourceContactId = z.infer<typeof SourceContactId>;

export const SOURCE_TYPES = [
  "CONCIERGE",
  "PERSONAL_ASSISTANT",
  "LIFESTYLE_MANAGER",
  "PRIME_CONCIERGE",
  "OPEN_SOURCE",
] as const;

export const SourceType = z.enum(SOURCE_TYPES, {
  message: ETranslations.FIELD_INVALID,
});

export type SourceType = z.infer<typeof SourceType>;

export const SourceContact = z.object({
  id: SourceContactId,
  label: SourceType.optional(),
  phone: z.string().nullish(),
  extension_phone: z.string().nullish(),
  email: z.string().nullish(),
  surname: z.string().nullish(),
  middle_name: z.string().nullish(),
  name: z.string().nullish(),
});
export type SourceContact = z.infer<typeof SourceContact>;

export const SourceContactWithOldSources = SourceContact.extend({
  id: SourceContactId.nullable(),
  label: SourceType.nullable().optional(),
}).nullable();

export const Organization = z.object({
  id: OrganizationId,
  source_name: OrganizationName,
  organization_type: z.string().nullable().optional(),
  address: z.string().nullable().optional(),
  phone: z.string().nullable().optional(),
  organization_uuid: z.string().uuid().nullable().optional(),
});
export type Organization = z.infer<typeof Organization>;

const SourceWithOrganization = z.object({
  source_id: SourceId,
  source_contact: SourceContactWithOldSources,
  source_type: z.literal("ORGANIZATION_PERSON"),
  source_organization: Organization,
});

const SourceOrganization = z.object({
  source_id: SourceId,
  source_contact: SourceContactWithOldSources.optional(),
  source_type: z.literal("ORGANIZATION"),
  source_organization: Organization,
});

const SourceWithOutOrganization = z.object({
  source_id: SourceId,
  source_contact: SourceContactWithOldSources,
  source_type: z.literal("PERSONAL"),
  source_organization: z.null().optional(),
});

export const Source = z.discriminatedUnion("source_type", [
  SourceOrganization,
  SourceWithOrganization,
  SourceWithOutOrganization,
]);
export type Source = z.infer<typeof Source>;

export const SourceSearchDTO = z.object({
  content: Source.array(),
  ...PageableScheme,
});
export type SourceSearchDTO = z.infer<typeof SourceSearchDTO>;

export type SourceSearchParams = Partial<{
  s: string;
  labels: SourceType[];
}>;

export const SourceContactSearchDTO = z.object({
  content: SourceContact.array(),
  ...PageableScheme,
});
export type SourceContactSearchDTO = z.infer<typeof SourceContactSearchDTO>;

export const OrganizationSearchDTO = z.object({
  content: z
    .object({
      id: OrganizationId,
      source_name: OrganizationName,
      organization_type: z.string().nullable(),
      address: z.string().nullable(),
      phone: z.string().nullable(),
      organization_uuid: z.string().uuid().nullable(),
    })
    .array(),
  ...PageableScheme,
});

export type OrganizationSearchDTO = z.infer<typeof OrganizationSearchDTO>;

export type CreateOrganizationPayload = {
  type?: string;
  address?: string;
  phone?: string;
  name: string;
  organization_uuid?: string;
  email?: string;
};

export type CreateSourceContactPayload = {
  surname?: string;
  name: string;
  email?: string;
  phone: string;
  middle_name?: string;
  extension_phone?: string;
};

export type CreateSourcePayload = {
  source_organization_id: OrganizationId;
  source_contact_id: SourceContactId;
  label?: SourceType;
};
