import {
  ConfirmMessage,
  ConfirmMessageActions,
  ConfirmMessageHeader,
  ConfirmMessageText,
} from "components/ConfirmModal";
import { FormClose } from "components/MultiStepForm";
import { useState } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogProvider,
  LinkButton,
} from "ui-kit";

const CreateBookingFormCloseDialog = ({
  onAfterClose,
}: {
  onAfterClose: () => void;
}) => {
  const { formatMessage } = useIntl();
  return (
    <DialogProvider>
      <Dialog initialOpenModal onAfterClose={onAfterClose}>
        <DialogCloseButton />
        <DialogContent>
          <ConfirmMessage>
            <ConfirmMessageHeader>
              {formatMessage({
                id: ETranslations.CLOSE_BOOKING_HEADER,
              })}
            </ConfirmMessageHeader>
            <ConfirmMessageText>
              {formatMessage({
                id: ETranslations.CLOSE_BOOKING_TEXT,
              })}
            </ConfirmMessageText>
            <ConfirmMessageActions>
              <LinkButton variant="dangerous" to="/dashboard">
                {formatMessage({
                  id: ETranslations.CLOSE_BOOKING_CONFIRM,
                })}
              </LinkButton>
              <DialogCloseButton isCancelButton variant="secondary">
                {formatMessage({ id: ETranslations.BASE_CANCEL })}
              </DialogCloseButton>
            </ConfirmMessageActions>
          </ConfirmMessage>
        </DialogContent>
      </Dialog>
    </DialogProvider>
  );
};

export const CrateBookingFormCloseButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <FormClose onClick={() => setIsOpen(true)} />
      {isOpen && (
        <CreateBookingFormCloseDialog onAfterClose={() => setIsOpen(false)} />
      )}
    </>
  );
};
