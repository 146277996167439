import { zodResolver } from "@hookform/resolvers/zod";
import Checkbox from "common/components/checkbox";
import { StepContainer } from "components/MultiStepForm";
import { SourceInput } from "components/SourceInput";
import { config } from "config";
import { CreateBookingFormFooter } from "containers/CreateBookingForm/Layout";
import { useCreateBookingFormContext } from "containers/CreateBookingForm/Provider";
import type {
  BookingData,
  StepNumber,
} from "containers/CreateBookingForm/model";
import { Tag } from "models/tags.model";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { AutoResizeTextarea, Button, Input, TextareaComposite } from "ui-kit";
import { commonFormErrorMap } from "utils";

import { type ExtraFormSchema, getExtraFormSchema } from "../model/schema";
import { DepositField } from "./DepositField";
import styles from "./ExtraStep.module.scss";
import { FauxTagsInput } from "./FauxTagsInput/FauxTagsInput";
import { TagsModal } from "./TagsModal/TagsModal";

const STEP_NUMBER: StepNumber = 5;

export const ExtrasStep = () => {
  const [tagsOpened, setTagsOpened] = useState(false);
  const { formatMessage } = useIntl();
  const { bookingData, updateBookingData, updateStepValidationState } =
    useCreateBookingFormContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<ExtraFormSchema>({
    resolver: zodResolver(getExtraFormSchema(formatMessage), {
      errorMap: commonFormErrorMap,
    }),
    defaultValues: {
      tags: bookingData.tags || [],
      comment: bookingData.comment,
      deposit: bookingData.deposit || {
        useDeposit: false,
        depositAmount: 0,
        depositMade: false,
      },
      source: bookingData.source,
    },
  });

  const handleExtraStepSubmit = () => {
    let success = false;
    let data: BookingData;
    return handleSubmit(
      ({ tags, comment, deposit, source }) => {
        data = updateBookingData({
          tags,
          comment,
          deposit,
          source,
        });
        updateStepValidationState({ step5: true });
        success = true;
      },
      () => {
        updateStepValidationState({ step5: false });
        success = false;
      },
    )().then(
      () =>
        ({ success, data }) as
          | {
              success: true;
              data: BookingData;
            }
          | {
              success: false;
              data: undefined;
            },
      () =>
        ({ success, data }) as
          | {
              success: true;
              data: BookingData;
            }
          | {
              success: false;
              data: undefined;
            },
    );
  };

  return (
    <>
      <StepContainer>
        <div className={styles.extraStep}>
          <Controller
            control={control}
            name="tags"
            render={({ field }) => (
              <FauxTagsInput
                tags={field.value}
                onClick={() => setTagsOpened(true)}
                onChange={field.onChange}
              />
            )}
          />
          <TextareaComposite.Root
            label={formatMessage({ id: ETranslations.RESERVATION_NOTE })}
            maxLength={500}
            className={styles.textareaContainer}
          >
            <TextareaComposite.Counter className={styles.textareaCounter} />
            <TextareaComposite.Input
              className={styles.textarea}
              {...register("comment")}
            />
          </TextareaComposite.Root>
          <DepositField control={control} />
          <label>
            {formatMessage({ id: ETranslations.SOURCE })}
            <Controller
              control={control}
              name="source"
              render={({ field }) => (
                <SourceInput
                  className={styles.sourceInput}
                  phone={field.value?.phone}
                  fullName={field.value?.fullName}
                  placeholder={formatMessage(
                    { id: ETranslations.BASE_SELECT_ENTITY },
                    {
                      entity: formatMessage({
                        id: ETranslations.SOURCE,
                      }).toLowerCase(),
                    },
                  )}
                  onSelect={field.onChange}
                  onReset={() => field.onChange(undefined)}
                />
              )}
            />
          </label>
        </div>

        {tagsOpened && (
          <Controller
            control={control}
            name="tags"
            render={({ field }) => (
              <TagsModal
                initialValue={field.value}
                onClose={() => setTagsOpened(false)}
                onChange={(value) => {
                  field.onChange(value);
                  setTagsOpened(false);
                }}
              />
            )}
          />
        )}
      </StepContainer>
      <CreateBookingFormFooter
        currentStep={STEP_NUMBER}
        onBeforeNavigate={handleExtraStepSubmit}
      />
    </>
  );
};
