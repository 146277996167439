import { zodResolver } from "@hookform/resolvers/zod";
import { useUpdateSourceMutation } from "features/api/source-api";
import {
  type OrganizationId,
  type OrganizationName,
  SOURCE_TYPES,
  Source,
  type SourceContactId,
  type SourceType,
} from "models/source.model";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Button, FieldError, LinkButton, SelectBasic } from "ui-kit";
import { commonFormErrorMap } from "utils";

import {
  SourceFormSchema,
  type SourceFormSchemaInput,
  getSourceFormSchema,
} from "../../model/schema";
import { SourceContactFields } from "./SourceContactFields";
import styles from "./SourceForm.module.scss";
import { SourceOrganizationFields } from "./SourceOrganizationFields";
import { useCreateSource } from "./useCreateSource";

const SourceForm = ({
  sourceContactId,
  surname,
  name,
  middleName,
  phone,
  additionalPhone,
  email,
  type,
  organizationId,
  organizationName,
  organizationAddress,
  organizationEmail,
  organizationPhone,
  disabled,
  errors,
  onSubmit,
}: {
  sourceContactId?: SourceContactId;
  surname?: string;
  name?: string;
  middleName?: string;
  phone?: string;
  additionalPhone?: string;
  email?: string;
  type?: SourceType;
  organizationId?: OrganizationId;
  organizationName?: string;
  organizationAddress?: string;
  organizationEmail?: string;
  organizationPhone?: string;
  disabled?: boolean;
  errors?: string[];
  onSubmit: (data: SourceFormSchema) => void;
}) => {
  const { formatMessage } = useIntl();
  const methods = useForm<SourceFormSchemaInput, unknown, SourceFormSchema>({
    resolver: zodResolver(getSourceFormSchema(formatMessage), {
      errorMap: commonFormErrorMap,
    }),
    defaultValues: {
      sourceContactId: sourceContactId?.toString(),
      surname,
      name,
      middleName,
      phone,
      additionalPhone,
      type,
      email,
      organizationId: organizationId?.toString(),
      organizationName,
      organizationAddress,
      organizationEmail,
      organizationPhone,
    },
    mode: "all",
  });
  methods.formState.errors && console.log(methods.formState.errors);
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={styles.form}
        id="source-form"
      >
        <SourceContactFields />
        <SourceOrganizationFields />
        <Controller
          control={methods.control}
          name="type"
          render={({ field: { onChange, value } }) => (
            <SelectBasic
              label={formatMessage({ id: ETranslations.TYPE })}
              options={SOURCE_TYPES.map((t) => ({ value: t, label: t }))}
              value={
                value
                  ? { value, label: formatMessage({ id: value }) }
                  : undefined
              }
              onChange={(option) =>
                option &&
                (Array.isArray(option)
                  ? onChange(option[0].value)
                  : onChange(
                      (option as { value: SourceType; label: SourceType })
                        .value,
                    ))
              }
              error={
                methods.formState.errors.type?.message &&
                formatMessage(
                  { id: methods.formState.errors.type.message },
                  {
                    field: formatMessage({
                      id: ETranslations.TYPE,
                    }).toLowerCase(),
                  },
                )
              }
            />
          )}
        />
        {(errors?.length || methods.formState.errors.root) && (
          <section className={styles.formErrors}>
            <FieldError
              error={
                errors
                  ?.concat(methods.formState.errors.root?.message || "")
                  .filter(Boolean) as string[]
              }
            />
          </section>
        )}
      </form>
      <footer className={styles.formFooter}>
        <LinkButton to=".." variant="secondary">
          {formatMessage({ id: ETranslations.BASE_CANCEL })}
        </LinkButton>
        <Button
          disabled={disabled}
          type="submit"
          form="source-form"
          variant="primary"
        >
          {formatMessage({ id: ETranslations.BASE_SAVE })}
        </Button>
      </footer>
    </FormProvider>
  );
};

export const EditSource = () => {
  const source = useOutletContext<Source>();
  const [update, { isLoading, error }] = useUpdateSourceMutation();
  return (
    <SourceForm
      surname={source.source_contact?.surname || undefined}
      name={source.source_contact?.name || undefined}
      middleName={source.source_contact?.middle_name || undefined}
      phone={source.source_contact?.phone || undefined}
      additionalPhone={source.source_contact?.extension_phone || undefined}
      email={source.source_contact?.email || undefined}
      type={source.source_contact?.label || undefined}
      organizationId={source.source_organization?.id || undefined}
      organizationName={source.source_organization?.source_name || undefined}
      //
      disabled={isLoading}
      errors={error && [JSON.stringify(error)]}
      //
      onSubmit={(updatedSource) =>
        update({
          id: source.source_id,
          //@ts-ignore
          source_organization:
            updatedSource.organizationName || updatedSource.organizationId
              ? {
                  id: updatedSource.organizationId,
                  source_name:
                    updatedSource.organizationName as OrganizationName,
                }
              : undefined,
          source_contact: {
            id: source.source_contact?.id || null,
            phone: updatedSource.phone,
            extension_phone: updatedSource.additionalPhone || undefined,
            email: updatedSource.email || undefined,
            surname: updatedSource.surname || undefined,
            middle_name: updatedSource.middleName || undefined,
            name: updatedSource.name,
          },
          label: updatedSource.type,
        })
      }
    />
  );
};

export const CreateSource = () => {
  const {
    isLoading,
    errors,
    handleCreateOrganization,
    handleCreateSourceContact,
    handleCreateSource,
  } = useCreateSource();
  return (
    <SourceForm
      //
      disabled={isLoading}
      errors={Object.values(errors).map((error) => JSON.stringify(error))}
      //
      onSubmit={(updatedSource) => {
        const organizationId =
          updatedSource.organizationId ||
          handleCreateOrganization({
            name: updatedSource.organizationName!,
            address: updatedSource.organizationAddress,
            phone: updatedSource.organizationPhone,
            email: updatedSource.organizationEmail,
          }).then((data) => data.id);

        const sourceContactId =
          updatedSource.sourceContactId ||
          handleCreateSourceContact({
            surname: updatedSource.surname || undefined,
            name: updatedSource.name || '',
            middle_name: updatedSource.middleName || undefined,
            phone: updatedSource.phone || '',
            email: updatedSource.email || undefined,
            extension_phone: updatedSource.additionalPhone || undefined,
          }).then((data) => data.id);

        Promise.all([organizationId, sourceContactId]).then((result) =>
          handleCreateSource({
            label: updatedSource.type,
            source_organization_id: result[0],
            source_contact_id: result[1],
          }),
        );
      }}
    />
  );
};
