import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ETranslations } from "types/translates";
import {
  Card,
  DatePicker,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogProvider,
  Input,
} from "ui-kit";

import { CreateProgramForm } from "./CreateProgramForm";
import styles from "./CreateProgramModal.module.scss";

export const CreateProgramModal = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  return (
    <DialogProvider>
      <Dialog
        initialOpenModal
        onAfterClose={() => navigate("..")}
        className={styles.dialog}
        position="left"
      >
        <DialogContent className={styles.dialogContent}>
          <Card as="section">
            <Card.Header
              title={formatMessage(
                { id: ETranslations.ENTITY_CREATING },
                {
                  entity: formatMessage({
                    id: ETranslations.PROGRAM_ALT_2,
                  }).toLowerCase(),
                },
              )}
              controls={<DialogCloseButton />}
            />
            <CreateProgramForm />
          </Card>
        </DialogContent>
      </Dialog>
    </DialogProvider>
  );
};
