import {
  DailyProgramDTO,
  type DailyProgramId,
  DailyProgramsSearchDTO,
} from "models/daily-program";
import type { RestaurantId } from "models/restaurant.model";

import { IResponse } from "../../models/common";
import { RequiredBy } from "./../../types/commons";
import { api } from "./api";

export const dailyProgramApi = api
  .enhanceEndpoints({
    addTagTypes: ["DailyProgram", "DailyPrograms"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchDailyProgramById: build.query<DailyProgramDTO, DailyProgramId>({
        query: (programId) => ({
          url: `v2/daily-program/${programId}`,
        }),
        transformResponse: (response: IResponse<DailyProgramDTO>) => {
          const result = DailyProgramDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: (_, __, programId) => [
          {
            type: "DailyProgram",
            id: programId,
          },
        ],
      }),
      fetchDailyProgramByDate: build.query<
        DailyProgramDTO,
        { restaurant_id: RestaurantId; date: string }
      >({
        query: (params) => ({
          url: `v2/daily-program/daily`,
          params,
        }),
        transformResponse: (response: IResponse<DailyProgramDTO>) => {
          const result = DailyProgramDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: (result, __, args) => [
          {
            type: "DailyProgram",
            id: result?.id ?? `${args.restaurant_id}-${args.date}`,
          },
        ],
      }),
      fetchDailyProgramPage: build.query<
        DailyProgramsSearchDTO,
        { restaurant_id: RestaurantId; search_string?: string } & {
          page?: number;
          size?: number;
          sort?: string;
        }
      >({
        query: (params) => ({
          url: `v2/daily-program/search`,
          params,
        }),
        transformResponse: (response: IResponse<DailyProgramsSearchDTO>) => {
          const result = DailyProgramsSearchDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: (_, __, args) => [
          {
            type: "DailyPrograms",
            id: args.page || 0,
          },
        ],
      }),
      createDailyProgram: build.mutation<
        DailyProgramDTO,
        Omit<DailyProgramDTO, "id">
      >({
        query: (payload) => ({
          url: "v2/daily-program",
          method: "POST",
          body: payload,
        }),
        transformResponse: (response: IResponse<DailyProgramDTO>) => {
          const result = DailyProgramDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        invalidatesTags: (result) => [
          {
            type: "DailyProgram",
            id: result?.id,
          },
          "DailyPrograms",
        ],
      }),
      updateDailyProgram: build.mutation<
        DailyProgramDTO,
        RequiredBy<DailyProgramDTO, "id">
      >({
        query: (payload) => ({
          url: `v2/daily-program/${payload.id}`,
          method: "PUT",
          body: payload,
        }),
        invalidatesTags: (_, __, args) => [
          {
            type: "DailyProgram",
            id: args.id,
          },
          "DailyPrograms",
        ],
      }),
      deleteDailyProgram: build.mutation<unknown, DailyProgramId>({
        query: (programId) => ({
          url: `v2/daily-program/${programId}`,
          method: "DELETE",
        }),
        invalidatesTags: (_, __, programId) => [
          {
            type: "DailyProgram",
            id: programId,
          },
          "DailyPrograms",
        ],
      }),
    }),
  });

export const {
  useCreateDailyProgramMutation,
  useDeleteDailyProgramMutation,
  useUpdateDailyProgramMutation,
  useFetchDailyProgramByDateQuery,
  useFetchDailyProgramByIdQuery,
  useFetchDailyProgramPageQuery,
  useLazyFetchDailyProgramPageQuery,
} = dailyProgramApi;
