import { restaurantSelector } from "features/AppContext";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ETranslations } from "types/translates";
import { Card, DatePicker, Input } from "ui-kit";

import styles from "./CreateProgramForm.module.scss";

export const CreateProgramForm = () => {
  const { formatMessage } = useIntl();
  const restaurant = useSelector(restaurantSelector);
  return (
    <Card.Content as="fieldset">
      <input
        type="hidden"
        name="restaurantId"
        readOnly
        value={restaurant.restaurant_id}
      />
      <Input
        label={formatMessage({ id: ETranslations.SHIFTS_NAME })}
        name="programName"
        required
      />
      <DatePicker
        name="startDate"
        className={styles.datepicker}
        customInputClassName={styles.datePickerInput}
        customInputFormatDate="ddd, DD.MM.YYYY"
        dateFormatCalendar="MMMM"
        selected={new Date()}
        onChange={console.log}
      />
    </Card.Content>
  );
};
